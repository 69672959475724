.button-with-background-color {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    max-width: 300px;
    padding: 14px 40px;
    margin: 0 auto;
    background-color: black;
    border-radius: 10px;
    transition: background-color 0.7s, opacity 0.7s;
}

.button-with-background-color:hover {
    cursor: pointer;
    background-color: #87CEEB;
}

@media screen and (max-width: 790px) {
    .button-with-background-color {
        padding: 10px 20px;
    }
}

/* Тескт */
.button-with-background-color__text {
    color: white !important;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    padding: 0 !important;
    margin: 0;
}

@media screen and (max-width: 790px) {
    .button-with-background-color__text {
        font-size: 15px;
    }
}

/* disabled */
.button-with-background-color__disabled {
    opacity: 0.3;
}

.button-with-background-color__disabled:hover {
    cursor: default;
    background-color: black;
}