.button-with-border {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 3px solid #000000;
    padding: 10px 30px;
    min-width: 130px;
    transition: background-color 0.7s, border-color 0.7s, opacity 0.7s;
}

@media screen and (max-width: 790px) {
    .button-with-border {
        padding: 10px 20px;
        border: 2px solid #000000;
        min-width: 120px;
    }
}

/* текст */
.button-with-border__text {
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    padding: 0 !important;
    margin: 0;
}

@media screen and (max-width: 790px) {
    .button-with-border__text {
        font-size: 15px;
    }
}

/* disabled */
.button-with-border__disabled {
    opacity: 0.3;
}

.button-with-border__disabled:hover {
    cursor: default !important;
    background-color: transparent !important;
    border-color: black !important;
}

