.search-form {
    width: calc(100vw - 140px); 
    max-width: 950px;
    margin: 0 auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 769px) {
    .search-form {
        width: calc(100vw - 60px);
    }
}

@media screen and (max-width: 769px) {
    .search-form {
        width: calc(100vw - 60px);
    }
}

@media screen and (max-width: 380px) {
    .search-form {
        width: calc(100vw - 20px);
        flex-direction: column;
    }
}