.new-address-page {
    margin-bottom: 30px;
    max-width: 900px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

/* _button-infotool */
.new-address-page__button-infotool {
    justify-content: center;
    align-items: center;
}