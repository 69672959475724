.item-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    transition: opacity 0.7s;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin: 5px 0;
}

/* __text */
.item-list__text{
    font-size: 17px;
    padding: 3px 0;
    margin: 0;
    white-space: 'normal';
}

@media screen and (max-width: 790px) {
    .item-list__text{
        font-size: 14px;
    }
}

/* __remove */
.item-list__remove {
    font-size: 15px;
    font-weight: 500;
    width: 30px;
    height: 100%;
    text-align: center;
    border-radius: 50px;
    padding: 5px 0 5px 0;
    transition: background-color 0.7s;
    margin: 0 0 0 5px;
}

.item-list__remove:hover {
    cursor: pointer;
    background-color: rgb(186, 186, 186);
}

@media screen and (max-width: 790px) {
    .item-list__remove {
        padding: 0 0 3px 0;
    }
}

/* __name */
.item-list__name {
    max-width: 70%;
    word-wrap: break-word;
    /* Разрешаем перенос слов, если текст не помещается */
    white-space: normal;
    /* Разрешаем перенос текста на следующую строку */
}

/* __container */
.item-list__container{
    display: flex;
    flex-direction: row;
    align-items: end;
    min-width: 20%;
    justify-content: space-between;
}

@media screen and (min-width: 790px) {
    .button-with-border:hover {
        cursor: pointer;
        background-color: #87CEEB;
        border-color: #87CEEB;
    }
}