.navigator-button__text {
    font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica;
    font-weight: 500;
    font-size: 27px;
    padding: 30px;
    text-align: center;
}

@media screen and (max-width: 972px) {
    .navigator-button__text {
        font-size: 17px;
        padding: 20px;
        text-align: center;
    }
}