.button-with-img {
    width: 70px;
    height: 70px;
    background-color: transparent;
    border: 3px solid transparent;
    padding: 15px;
    border-radius: 50px;
    transition: border-color 0.7s;
}

.button-with-img:hover {
    cursor: pointer;
    border: 3px solid rgb(135, 135, 135);
}

/* img */
.button-with-img__img {
    width: 100%;
    height: 100%;
}