.footer__text {
    font-family: 'Inter', 'Helvetica Neue', Arial;
    font-weight: 500;
    font-size: 25px;
    width: 100%;
    text-align: left;
}

@media screen and (max-width: 790px) {
    .header-title {
        font-size: 20px;
    }
}