.date-range-picker {
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .date-range-picker {
        flex-direction: column;
        justify-content: space-between;
    }  
}