.input {
    border: 0;
    padding: 0 !important;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background-color: transparent;
    color: #000000;
    width: 100%;
}

.input::placeholder {
    padding: 5px 0 10px;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 17px;
    color: #706F6F;
}

@media screen and (max-width: 790px) {
    .input {
        padding: 10px 0;
        font-size: 15px;
        color: #000000;
        margin-top: 10px;
    }

    .input::placeholder {
        padding: 3px 0;
        font-size: 15px;
    }
}