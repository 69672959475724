.input-with-label-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 0 7px;
    margin-top: 0px;
}

@media screen and (max-width: 790px) {
    .input-with-label-container {
        padding: 10px 0;
        margin-top: 10px;
    }
}