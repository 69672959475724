.addresses{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.addresses__title {
    font-size: 24px;
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial;
    text-align: center;
    font-weight: 500;
    margin: 0;
    padding: 30px 0 60px;
}

.clients__search-form {
    margin: 0 0 40px;
}

.addresses__containeiner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.addresses__container-buttons {
    display: grid;
    width: 100%;
    grid-gap: 10px; /* Расстояние между элементами */
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Автоматическое определение количества столбцов */
    margin: 30px 0;
}

@media screen and (max-width: 450px) {
    .addresses__container-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100px;
    }
}

.addresses__addressesEmployees-button-add {
    margin: 30px 0;
}

.addresses__addressesEmployees{
    margin: 30px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.addresses__container-balance-info{
    width: 100%;
    background-color: rgba(0,0,0,0.1);
    padding: 10px;
    margin-top: 15px;
    border-radius: 10px;
}

.addresses__container-balance-controle{
    flex-direction: row;
}

.addresses__container-balance-controle-text{
    margin: 0;
    padding: 0;
}

.addresses__container-balance-controle-button{
    margin: 10px auto;
    width: 250px;
}