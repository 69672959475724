.form-add-record {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0 70px;
    max-width: 900px;
}


@media screen and (max-width: 790px) {
    .form-add-record {
        padding-top: 20px;
    }
}

/* __title */
.form-add-record__title {
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    margin: 0;
    padding: 30px 0 60px;
}

@media screen and (max-width: 790px) {
    .form-add-record__title {
        font-size: 20px;
        padding: 30px 0 40px;
    }
}

/* __loader */
.form-add-record__loader {
    width: 25px;
    position: absolute;
    right: 0;
    bottom: 23px;
}

@media screen and (max-width: 790px) {
    .form-add-record__loader {
        bottom: 10px;
    }
}

/* __container-list-works */
.form-add-record__container-list {
    background-color: #D9D9D9;
    width: 90%;
    min-height: 30px;
    border-radius: 50px;
    margin: 30px 0 30px;
    padding: 30px 10px 30px;
}

/* __container-input */
.form-add-record__container-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 0 30px;
}

/* __list  */
.form-add-record__list {
    width: 90%;
    padding: 30px;
}