.info-tooltip {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.4s, opacity 0.4s linear;
    z-index: 1;
}

/* __massage */
.tooltip__massage {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 29.05px;
    opacity: 1;
    max-width: 358px;
    text-align: center;
    margin: 30px auto;
}

/* _show */
.info-tooltip_show {
    visibility: visible;
    opacity: 1;
}

/* __overlay */
.info-tooltip__overlay{
    /* position: relative; */
}

/* __container */
.info-tooltip__container {
    width: 430px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-color: white;
    min-height: 300px;
    border-radius: 30px;
    max-height: 90vh;
    overflow-y: auto;
    z-index: 1;
}

@media screen and (max-width: 450px) {
    .info-tooltip__container {
        width: 282px;
    }
}

/* __close-button */
.info-tooltip__close-button {
    margin-left: auto;
    padding-bottom: 10px;
    width: 32px;
    height: 32px;
    margin-right: calc(50vw - 220px);
    margin-bottom: 16px;
    background-image: url(./../../../../images/CloseIcon.svg);
    background-color: black;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    border: 0;
    z-index: 999;
    background-color: transparent;
}

.info-tooltip__close-button:hover {
    opacity: 0.6;
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .info-tooltip__close-button {
        width: 20px;
        height: 20px;
        margin-right: calc(50vw - 220px);
        margin-bottom: 16px;
        right: 0px;
    }
}

@media screen and (max-width: 500px) {
    .info-tooltip__close-button {
        margin-right: calc(50vw - 149px); 
    }
}