.employees{
    margin: 30px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* __containeiner */
.employees__containeiner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/* employees-button-add */
.employees-button-add {
    margin: 30px 0;
}

/* __container-buttons */
.employees__container-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 420px;
    margin: 30px 0;
}

@media screen and (max-width: 450px) {
    .employees__container-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100px;
    }
}

/* __list */
.employees__list{
    width: 100%;
    max-width: 950px;
}

/* __item-list */
.employees__item-list {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    transition: background-color 0.7s;
}

.employees__item-list:hover {
    cursor: pointer;
    background-color: rgb(223, 223, 223);
}

/* __item-list-container */
.employees__item-list-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0;
}

/* __item-list-text */
.employees__item-list-text {
    font-size: 15px;
    font-weight: 500;
    padding: 0;
    margin: 0;
}

/* __form-add-employee */
.employees__form-add-employee{
    padding: 30px;
}

.employees__form-add-employee-button{
    width: 300px;
    margin: 30px auto;
}